<template>
  <div @click="$emit('sync:showList', true)">
    <Autocomplete
      ref="autocomplete"
      key="project_member"
      :listHeight="208"
      :useChips="false"
      :directInput="false"
      :excludeLoginUser="true"
      :selection.sync="_selection"
      :placeholder="$t('common.48')"
      :typeList="['MEMBER']"
      @keydown:esc="keydownEsc"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-autocomplete-wrapper::v-deep {
  .cr-autocomplete-placeholder {
    left: 16px;
  }
  .v-input .v-input__slot input {
    padding-left: 16px !important;
  }

  .cr-autocomplete-list-wrapper {
    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
      0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
</style>

<script>
import { mapActions } from "vuex";
import Autocomplete from "@/commons/views/autocomplete/Autocomplete";

export default {
  components: { Autocomplete },
  props: {
    prevMembers: {
      type: Array,
      default: () => []
    },
    newMembers: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    defaultMemberAuthority: {
      type: String,
      default: "READ"
    }
  },
  computed: {
    _selection: {
      get() {
        return {};
      },
      set(user) {
        const index = this.prevMembers.findIndex(m => m.userId == user.userId);
        if (index !== -1) {
          return this.openSnackbar({ message: "이미 참여중인 사용자입니다." });
        }

        const n_index = this.newMembers.findIndex(m => m.userId == user.userId);
        if (n_index !== -1) {
          return this.openSnackbar({ message: "이미 추가하신 사용자입니다." });
        }

        const joinStatus = "INVITED";
        const { defaultMemberAuthority: authority } = this;
        this.$emit("sync:newMembers", [
          ...this.newMembers,
          { authority, joinStatus, ...user }
        ]);
      }
    }
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    keydownEsc() {
      if (!this.showActions) return;

      const { $refs } = this.$refs?.autocomplete || {};
      if (!$refs.textField) return;

      this.$emit("sync:showList", false);
      this.$emit("sync:newMembers", []);
      $refs.textField.blur();
    }
  }
};
</script>
