<template>
  <div class="mt-3 fill-height">
    <div class="grey--text text--darken-2 font-weight-black">
      멤버 초대
    </div>

    <AddMembers
      :newMembers="members"
      :showActions="false"
      :showOrganizationInfo="false"
      :defaultMemberAuthority="getDefaultMemberAuthority()"
      @sync:newMembers="m => (members = m)"
    />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .cr-add-member-wrapper {
    width: auto;
    &.cr-show {
      height: calc(100% - 34px);
    }
  }
}
</style>

<script>
import { mapActions } from "vuex";
import AddMembers from "@/flow/views/components/commons/addMembers";

export default {
  components: { AddMembers },
  data() {
    // 프로젝트 수정에선 멤버수정 X
    return { members: [] };
  },
  methods: {
    ...mapActions("snackbar", ["openSnackbar"]),
    getData() {
      return { members: this.members };
    },
    getDefaultMemberAuthority() {
      const { defaultMemberAuthority } = this.$parent.$refs["secure"] || {};
      return defaultMemberAuthority || "READ";
    }
  }
};
</script>
