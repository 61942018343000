<template>
  <div v-if="showList" class="fill-height">
    <div class="cr-project-member-list">
      <div v-if="isEmpty" class="d-flex flex-column align-center pt-16">
        함께 프로젝트를 진행할 멤버를 초대해 주세요.
      </div>

      <Member
        v-for="(member, idx) in newMembers"
        :key="idx"
        :member="member"
        v-bind="$props"
        v-on="$listeners"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.fill-height {
  position: relative;
  .cr-project-member-list {
    height: 100%;
    overflow-y: auto;
    border: thin solid rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }
}
</style>

<script>
import Member from "./Member.vue";

export default {
  components: { Member },
  props: {
    newMembers: {
      type: Array,
      default: () => []
    },
    showList: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showOrganizationInfo: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isEmpty() {
      return !this.newMembers.length;
    }
  }
};
</script>
