<template>
  <div class="cr-progress-wrapper">
    <v-progress-linear
      v-if="loading"
      indeterminate
      height="8"
      color="primary darken-2"
    />
  </div>
</template>

<style lang="scss" scoped>
.cr-progress-wrapper {
  min-height: 8px;
}
</style>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>
