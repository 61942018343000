<template>
  <div class="cr-add-member-actions">
    <v-btn outlined depressed color="grey darken-1" @click="cancel">
      취소
    </v-btn>
    <v-btn depressed color="primary" class="ml-1" @click="$emit('add:members')">
      초대
    </v-btn>
  </div>
</template>

<style lang="scss" scoped>
.cr-add-member-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 18px;
}
</style>

<script>
export default {
  methods: {
    cancel() {
      this.$emit("sync:showList", false);
      this.$emit("sync:newMembers", []);
    }
  }
};
</script>
