<template>
  <div class="d-flex justify-space-between align-center px-4 my-2">
    <div class="d-flex align-center body-2" :title="memberFullName">
      <span class="black--text">{{ memberName }}</span>
      <v-btn icon x-small @click="deleteMember">
        <v-icon title="삭제" size="16" color="grey">mdi-close</v-icon>
      </v-btn>
    </div>

    <CrSelect :items="items" :value.sync="_authority" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep {
  .cr-flow-select {
    max-width: 120px;
  }
}
</style>

<script>
import CrSelect from "@/flow/views/components/commons/select";

export default {
  components: { CrSelect },
  props: {
    member: {
      type: Object,
      default: () => ({})
    },
    newMembers: {
      type: Array,
      default: () => []
    },
    showOrganizationInfo: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    items() {
      return [
        { text: "게스트", value: "READ" },
        { text: "편집자", value: "EDIT" },
        { text: "운영자", value: "ADMIN" }
        // { text: "마스터", value: "MASTER" }
      ];
    },
    memberName() {
      const { name, organizationName, jobGrade, jobTitle } = this.member;
      if (!this.showOrganizationInfo) return name;
      return [name, organizationName, jobGrade, jobTitle]
        .filter(Boolean)
        .join(" / ");
    },
    memberFullName() {
      return this.member.value;
    },
    _authority: {
      get() {
        return this.member.authority;
      },
      set(authority) {
        const { userId } = this.member;
        const newMembers = this.newMembers.map(m => ({ ...m }));
        const index = newMembers.findIndex(m => m.userId === userId);
        if (index === -1) return;

        newMembers[index].authority = authority;
        this.$emit("sync:newMembers", newMembers);
      }
    }
  },
  methods: {
    deleteMember() {
      const { userId } = this.member;
      const newMembers = this.newMembers.filter(m => m.userId !== userId);
      this.$emit("sync:newMembers", newMembers);
    }
  }
};
</script>
