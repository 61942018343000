<template>
  <div class="cr-add-member-wrapper" :class="{ 'cr-show': showList }">
    <Autocomplete v-bind.sync="$props" v-on="$listeners" />
    <Progress v-bind="$props" />
    <List v-bind.sync="$props" v-on="$listeners" />
    <Actions v-if="showList && showActions" v-on="$listeners" />
  </div>
</template>

<style lang="scss" scoped>
.cr-add-member-wrapper::v-deep {
  display: flex;
  flex-direction: column;
  width: 552px;
  &.cr-show {
    height: 547px;
  }
}
</style>

<script>
import Autocomplete from "./Autocomplete.vue";
import Progress from "./Progress.vue";
import List from "./list";
import Actions from "./Actions.vue";

export default {
  components: { Autocomplete, Progress, List, Actions },
  props: {
    prevMembers: {
      type: Array,
      default: () => []
    },
    newMembers: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    showList: {
      type: Boolean,
      default: true
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showOrganizationInfo: {
      type: Boolean,
      default: true
    },
    defaultMemberAuthority: {
      type: String,
      default: "READ"
    }
  }
};
</script>
